import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';

import {
  renderField,
  renderDayPickerB,
  renderNumber,
  SelectField,
  renderFilePicker,
  renderTextArea
} from "../../Utils/renderField/renderField";

function RegistroMarcaForm(props) {
  const { handleSubmit, crear, editar,
    status, lectura, setLogo, agentes,
    listaPais, titulares,
    comercial, principio_activo
  } = props;

  let autoFocus = true;
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  
  let disabled = false;
  if (crear === false && editar === false) {
    disabled = true;
  }

  const tipoMarca = [
    { label: "Denominativa", value: "1" },
  ];

  return (
    <div>
      <div className='row mb-2 '>
        <div className='col-12'>
          <Field
            autofocus={autoFocus}
            name="descripcion_marca"
            placeholder="Descripción marca"
            className="form-control"
            component={renderField}
            disabled={disabled}
            msj="Descripción marca"
          />
        </div>
      </div>
      <div className='row mb-2'>
        <div className='col-1'>
          <Field
            name="clase_marca"
            placeholder="Clase"
            className="form-control"
            component={renderNumber}
            disabled={disabled}
            msj="Clase"
          />
        </div>
        <div className='col-3'>
          <div className="form-floating">
            <Field
              name="tipo"
              options={tipoMarca}
              crear={crear}
              dato={crear === true ? 0 : lectura.tipo_marca}
              component={SelectField}
              className="form-select"
              disabled={disabled}
              msj="Tipo"
            />
          </div>
        </div>
        <div className='col-4'>
          <Field
            name="certificado"
            placeholder="Certificado"
            className="form-control"
            component={renderField}
            disabled={disabled}
            msj="Certificado"
          />
        </div>
        <div className='col-4'>
          <Field
            name="acta"
            placeholder="Acta"
            className="form-control"
            component={renderField}
            disabled={disabled}
            msj="Acta"
          />
        </div>
      </div>
      <div className='row mb-2'>
        <div className='col-3'>
          {agentes &&
            <div>
              {agentes.length >= 1 &&
                <div className="form-floating">
                  <Field
                    name="agente"
                    options={agentes}
                    crear={crear}
                    dato={crear === true ? 0 : lectura.agente}
                    component={SelectField}
                    className="form-select"
                    disabled={disabled}
                    msj="Agente"
                  />
                </div>
              }
            </div>
          }
        </div>
        <div className='col-3'>
          {listaPais &&
            <Field
              name="pais_marca"
              options={listaPais}
              crear={crear}
              dato={crear === true ? 0 : lectura.pais}
              component={SelectField}
              className="form-select"
              disabled={disabled}
              msj="Pais"
            />
          }
        </div>
        <div className='col-3'>
          {status &&
            <div>
              {status.length >= 1 &&
                <div className="form-floating">
                  <Field
                    name="status"
                    options={status}
                    crear={crear}
                    dato={crear === true ? 0 : lectura.status}
                    component={SelectField}
                    className="form-select"
                    disabled={disabled}
                    msj="Status"
                  />
                </div>
              }
            </div>
          }
        </div>
        <div className='col-3'>
          {titulares &&
            <div>
              {titulares.length >= 1 &&
                <div className="form-floating">
                  <Field
                    name="titular"
                    options={titulares}
                    crear={crear}
                    dato={crear === true ? 0 : lectura.titular}
                    component={SelectField}
                    className="form-select"
                    disabled={disabled}
                    msj="Titular"
                  />
                </div>
              }
            </div>
          }
        </div>
      </div>
      <div className='row mb-2'>
        <div className='col-3'>
          <Field
            disabled={disabled}
            name="fecha_vencimiento"
            component={renderDayPickerB}
            msj="Fecha vencimiento"
          />
        </div>
        <div className='col-3'>
          <Field
            disabled={disabled}
            name="fecha_concesion"
            component={renderDayPickerB}
            msj="Fecha concesión"
          />
        </div>
        <div className='col-3'>
          <Field
            disabled={disabled}
            name="fecha_sometida"
            component={renderDayPickerB}
            msj="Fecha sometida"
          />
        </div>
        <div className='col-3'>
          <Field
            disabled={disabled}
            name="fecha_actualizacion"
            component={renderDayPickerB}
            msj="Fecha ultima actualizacion"
          />
        </div>
      </div>
      <div className='mb-2 row'>
        <div className='col-12'>
          <Field
            name="limitacion"
            placeholder="Limitación"
            className="form-control"
            component={renderTextArea}
            disabled={disabled}
            msj="Limitación"
          />
        </div>
      </div>
      <div className='row mb-2'>
        <div className='col-3'>
          <Field
            disabled={disabled}
            name="renueva"
            component={renderField}
            msj="Renueva a"
          />
        </div>
        <div className='col-6'>
          <Field
            disabled={disabled}
            name="titulo_archivo"
            component={renderField}
            msj="Titulo archivo"
          />
        </div>
        <div className='col-3'>
          {principio_activo &&
            <div>
              {principio_activo.length >= 1 &&
                <div className="form-floating">
                  <Field
                    name="principio_activo"
                    options={principio_activo}
                    crear={crear}
                    dato={crear === true ? 0 : lectura.principio_activo}
                    component={SelectField}
                    className="form-select"
                    disabled={disabled}
                    msj="Principio activo"
                  />
                </div>
              }
            </div>
          }
        </div>
      </div>
      <div className='row mb-2'>
        <div className='col-3'>
          {comercial &&
            <div>
              {comercial.length >= 1 &&
                <div className="form-floating">
                  <Field
                    name="comercial"
                    options={comercial}
                    crear={crear}
                    dato={crear === true ? 0 : lectura.comercializada}
                    component={SelectField}
                    className="form-select"
                    disabled={disabled}
                    msj="Comercializada"
                  />
                </div>
              }
            </div>
          }
        </div>
      </div>
      <div className='row mb-2'>
        {(crear === true) &&
          <div className="col-4">
            <label htmlFor="logo">Logo de marca</label>
            <Field
              photo={null}
              setFile={setLogo}
              name="logo"
              component={renderFilePicker}
            />
          </div>
        }
        {(editar === true) && lectura.imagen === null &&
          <div className="col-4">
            <label htmlFor="logo">Logo de marca</label>
            <Field
              photo={null}
              setFile={setLogo}
              name="logo"
              component={renderFilePicker}
            />
          </div>
        }
        {(editar === true) && lectura.imagen &&
          <div className="col-4">
            <label htmlFor="logo">Logo de marca</label>
            <Field
              photo={lectura.imagen}
              setFile={setLogo}
              name="logo"
              component={renderFilePicker}
            />
          </div>
        }
        {(crear === false && editar == false) && lectura.imagen === null &&
          <div className="col-4">
            <label htmlFor="logo">Logo de marca</label>
            <Field
              photo={null}
              setFile={setLogo}
              name="logo"
              component={renderFilePicker}
            />
          </div>
        }
        {(crear === false && editar == false) && lectura.imagen &&
          <div className="col-4">
            <label htmlFor="logo">Logo de marca</label>
            <Field
              photo={lectura.imagen}
              setFile={setLogo}
              name="logo"
              disabled="true"
              component={renderFilePicker}
            />
          </div>
        }
      </div>
      <div className='d-flex flex-row mt-1'>
        {disabled == false &&
          <button
            className='btn mr-2 mb-3 btn-primary'
            onClick={handleSubmit}
          >
            {editar ? 'Modificar' : 'Guardar'}
          </button>
        }
        <a
          href={`/#/registro_marca/${id_emp[5]}/marca`}
          className='btn btn-secondary mb-3'
        >
          Regresar
        </a>
      </div>
    </div>

  );
}

RegistroMarcaForm.propTypes = {
  crear: PropTypes.bool,
  lectura: PropTypes.object,
  modulo: PropTypes.object,
};

export default reduxForm({
  form: 'FormRegistroMarca',
})(RegistroMarcaForm);
