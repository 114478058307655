import React, { Component } from 'react';
import Formulario from './InformesForm';

class Informes extends Component{
    
    componentDidMount = () => {
        const { listarMonedas, listarClientes, getModulo } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4]);
        listarMonedas();
        listarClientes();
    }

    render(){
        const { loader, reporteLibroVentas, monedas, reporteAntiguedadSaldos, reporteClientes,
            listarClientes, modulo, reporteEstadoCuenta, listarListaDePrecios, reporteListadoFacturas,
            reporteListadoAbonos, reporteListadoPrecios, reporteAnticipos,
            excelListadoAbonos, excelLibroVentas } = this.props;
        
        return(
            <div>
                
                <Formulario
                    monedas = {monedas}
                    reporteLibroVentas = {reporteLibroVentas}
                    loader = {loader}
                    listarClientes = {listarClientes}
                    listarListaDePrecios = {listarListaDePrecios}
                    reporteAntiguedadSaldos = {reporteAntiguedadSaldos}
                    modulo = {modulo}
                    reporteEstadoCuenta = {reporteEstadoCuenta}
                    reporteClientes = {reporteClientes}
                    reporteListadoFacturas = {reporteListadoFacturas}
                    reporteListadoAbonos = {reporteListadoAbonos}
                    reporteListadoPrecios = {reporteListadoPrecios}
                    reporteAnticipos = {reporteAnticipos}
                    excelListadoAbonos = {excelListadoAbonos}
                    excelLibroVentas = {excelLibroVentas}
                />
            </div>
        );
    }
}

export default Informes;
