import React, { Component } from 'react';
import Formulario from './InformesForm';

class Informes extends Component{
    componentDidMount = () => {
        const { getModulo, listarMonedas,listarProveedores } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4]);
        listarMonedas();
        listarProveedores();
    }
    render(){
        const { loader, reporteListaFacturas, reporteLibroCompras, excelLibroCompras, 
            modulo, monedas, listarProveedores, reporteEstadoCuenta,
            reporteAntiguedadSaldos, reporteProveedores, reporteListadoCompras,
            reporteListadoAbonos, reporteAnticipos } = this.props;
        return(
            <div>
                
                <Formulario
                    reporteListaFacturas = {reporteListaFacturas}
                    reporteLibroCompras = {reporteLibroCompras}
                    loader = {loader}
                    monedas = {monedas}
                    listarProveedores = {listarProveedores}
                    excelLibroCompras = {excelLibroCompras}
                    modulo = {modulo}
                    reporteAntiguedadSaldos = {reporteAntiguedadSaldos}
                    reporteEstadoCuenta = {reporteEstadoCuenta}
                    reporteProveedores = {reporteProveedores}
                    reporteListadoCompras = {reporteListadoCompras}
                    reporteListadoAbonos = {reporteListadoAbonos}
                    reporteAnticipos = {reporteAnticipos}
                />
                
            </div>
        );
    }
}

export default Informes;
