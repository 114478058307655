import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';

const LISTADO_ABONO = 'LISTADO_ABONO';
const LISTADO_TIPOS_DOCU = 'LISTADO_TIPOS_DOCU';
const LISTADO_PROVEEDOR = 'LISTADO_PROVEEDOR';
const GUARDAR_ABONO = 'GUARDAR_ABONO';
const GUARDAR_POLIZA = 'GUARDAR_POLIZA';
const DETALLE_POLIZA = 'DETALLE_POLIZA';
const LISTADO_DOCUMENTOS = 'LISTADO_DOCUMENTOS';
const LISTADO_MONEDAS = 'LISTADO_MONEDAS';
const DETALLE_DOCUMENTOS = 'DETALLE_DOCUMENTOS';
const LOADER = 'LOADER';
const LOADERC = 'LOADERC';
const NIVEL = 'NIVEL';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODOD';
const PARAMETRO = 'PARAMETRO';
const DOCUMENTOS = 'DOCUMENTOS';
const DETALLE = 'DETALLE';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setLoaderC = loaderC => ({
    type: LOADERC,
    loaderC,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let id_period = id_emp[7];
    //const params = { page, id, id_period };
    const params = { id, id_period };
    dispatch(setLoader(true));
    api.get('/abono_cxp', params).then((response) => {
        dispatch({ type: LISTADO_ABONO, data: response });
    
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar abonos',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarTiposDocu = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/tipo_abono', params).then((response) => {
        const tiposD = [];
        response.results.forEach(item => {
            if (item.tipo_documento === 'ABONO') {
                tiposD.push({
                    value: item.id,
                    label: item.tipo + ' - ' + item.descripcion,
                    search: item.categoria
                })
            }
        })
        dispatch({ type: LISTADO_TIPOS_DOCU, tipos: tiposD });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar tipos de abono',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarProveedores = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let tipo = 'compras';
    const params = { id, search, tipo }
    return api.get('/proveedor/empresa_proveedores', params).then(data => {
        const list_proveedor = [];
        if (data) {
            data.forEach(item => {
                list_proveedor.push({
                    value: item.id,
                    label: item.identificador + ' ' + item.nombre
                })
            })
        }
        return list_proveedor;
    }).catch((error) => {
        
        return [];
    })
}

export const listarCuentas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/cuentas/empresa_cuentas/', params).then(data => {
        const list_cuentas = [];
        if (data) {
            data.forEach(item => {
                if (item.tipo === 'A') {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre,
                        isDisabled: true
                    })
                } else {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre
                    })
                }
            })
        }
        return list_cuentas;
    }).catch((error) => {
        
        return [];
    })
}

export const listarCC = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/centrocosto/empresa_centro_costo/', params).then(data => {
        const list_cc = [];
        if (data) {
            data.forEach(item => {
                list_cc.push({
                    value: item.id,
                    label: item.codigo + ' ' + item.descripcion
                })
            })
        }
        return list_cc;
    }).catch((error) => {
        
        return [];
    })
}

const leerDetalle = id => (dispatch) => {
    api.get(`/poliza_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_POLIZA, detalle: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            3000
        );
    })
};

const leerDetalleMod = id => (dispatch) => {
    api.get(`/poliza_detalle/${id}`).then((response) => {
        response.polizaDetalle_polizaEncabezado = response
        dispatch({ type: GUARDAR_POLIZA, lectura: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            3000
        );
    })
};


export const listarNiveles = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    dispatch(setLoader(true));
    api.get('/niveles/nivel_cuenta', params).then((response) => {
        dispatch({ type: NIVEL, nivel: response });
    }).catch((error) => {
        
        NotificationManager.error(
            'Ocurrió un error al listar nivel',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminarLinea = id => (dispatch) => {
    const poliza = localStorage.getItem('idPolizaAbonoPro');
    dispatch(setLoader(true));
    api.eliminar(`poliza_detalle/${id}`).then(() => {
        const editar = window.location.href.includes('editar');
        if (editar) {
            dispatch(leerDetalleMod(poliza));
        } else {
            dispatch(leerDetalle(poliza));
        }
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar linea de poliza',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const registroCuenta = (formato) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.AbonoProveedorFormTabs.values;
    let cuenta = data.cuenta
    let f = formato.split('-');
    let cont = 0;
    let formatoCuenta = '';
    let digito = '';

    if (f.length === 3) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito;

    }

    if (f.length === 4) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito;
    }

    if (f.length === 5) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont + nivel4;
        digito = cuenta.substr(cont, nivel5);
        formatoCuenta = formatoCuenta + digito;
    }

    if (f.length === 6) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont + nivel4;
        digito = cuenta.substr(cont, nivel5);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel6 = f[5].length;
        cont = cont + nivel5;
        digito = cuenta.substr(cont, nivel6);
        formatoCuenta = formatoCuenta + digito;
    }
    let idPoliza = localStorage.getItem('idPolizaAbonoPro')
    const formData = {
        cuenta: formatoCuenta,
        nombre: data.nombre,
        tipo: data.tipoC,
        naturaleza: data.naturaleza,
        empresa: id_emp[5],
        poliza: idPoliza
    }
    dispatch(setLoaderC(true));
    api.post('/cuentas/', formData).then((response) => {
        if (idPoliza) {
            NotificationManager.success(
                'Cuenta creada' + ' ' + response.cuenta.cuenta + ' ' + response.cuenta.nombre,
                'Exito',
                0
            );
            response.cuenta = '';
            response.nombre = '';
            response.fecha = response.poliza.fecha;
            response.tipo = response.poliza.tipo;
            response.numero_poliza = response.poliza.numero_poliza;
            response.descripcion = response.poliza.descripcion;
            listarCuentas();

        } else {
            NotificationManager.success(
                'Cuenta creada' + ' ' + response.cuenta + ' ' + response.nombre,
                'Exito',
                0
            );
            response.cuenta = '';
            response.nombre = '';
            listarCuentas();
        }

    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });
}

export const reporteCuentas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let reporte = 'reporte';
    const params = { id, reporte };
    dispatch(setLoaderC(true));;
    api.getPdf('/cuentas', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_cuentas_contables.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });
}

export const registroAbono = (periodoD, datos) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormAbonoProveedor.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha_registro)
    let poliza = '';
    if (fecha>=fechaI && fecha<=fechaF){
        if (parseFloat(data.diferencia) === 0){
            if (datos.contabilidad === 'S') {
                poliza = 'abonos';
            }
            if (datos.contabilidad === 'N') {
                poliza = '';
            }
            const formData = {
                fecha_registro: data.fecha_registro,
                serie: data.serie.toUpperCase(),
                numero: data.numero,
                tipo_cargo: data.tipo,
                monto: data.monto,
                descripcion: data.descripcion,
                proveedor: data.proveedor_documento.value,
                moneda: localStorage.getItem('monedaAbPro'),
                tipo_cambio: localStorage.getItem('TC_abPro'),
                detalleAbono: data.detalleD,
                tipo: 'ABONO',
                poliza: poliza,
                modulo: 'CMP',
                empresa: id_emp[5],
                periodo: id_emp[7]
            }
            
            dispatch(setLoader(true));
            api.post('/abono_cxp/', formData).then((response) => {
                NotificationManager.success(
                    'Abono guardado correctamente',
                    'Exito',
                    3000
                );
                response.fecha_registro = response.documento.fecha_registro;
                response.serie = response.documento.serie;
                response.numero = response.documento.numero;
                response.descripcion = response.documento.descripcion;
                response.monto = response.documento.monto;
                response.totalAbono = response.totalA;
                response.proveedor = response.documento.proveedor
                response.proveedorN = response.documento.proveedorN;
                response.diferencia = parseFloat(response.documento.monto)-parseFloat(response.totalA)
                localStorage.setItem('idPolizaAbonoPro', response.idPoliza);
                localStorage.setItem('conf_Abono','T');
                const poliza = localStorage.getItem('idPolizaAbonoPro');
                localStorage.setItem('detalleID_AbonoPro', response.detalle);
                dispatch(initializeForm('FormAbonoProveedor', response));
                
                if (poliza){
                    dispatch(leerDetalle(poliza));
                }
                
              
            }).catch((error) => {
                localStorage.setItem('conf_Abono','F')
                NotificationManager.error(
                    error.msj,
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }else{
            localStorage.setItem('conf_Abono','F')
            Swal.fire({
                title: 'Abono de proveedor',
                text: `Abono con diferencia: ${new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(data.diferencia)}`,
                type: 'error',
            })    
        }
        
    }else {
        localStorage.setItem('conf_Abono','F')
        Swal.fire({
            title: 'Abono de proveedor',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })    
    }

}

export const modificarAbono = (periodoD, datos) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormAbonoProveedor.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha_registro)
    let id = data.id
    let poliza = '';
    if (fecha>=fechaI && fecha<=fechaF){
        if (parseFloat(data.diferencia) === 0){
            if (datos.contabilidad === 'S') {
                poliza = 'abonos';
            }
            if (datos.contabilidad === 'N') {
                poliza = '';
            }
            const formData = {
                id: id,
                fecha_registro: data.fecha_registro,
                monto: parseFloat(data.monto),
                descripcion: data.descripcion,
                tipo_cambio: data.tipo_cambio,
                tipo_cargo: data.tipo,
                moneda: data.moneda,
                numero: data.numero,
                poliza: poliza,
                idPoliza: localStorage.getItem('idPolizaAbonoPro'),
                tipo_cambio: localStorage.getItem('TC_abPro'),
            }
            dispatch(setLoader(true));
            api.put(`/abono_cxp/${id}/`, formData).then((response) => {
                localStorage.setItem('conf_Abono','T');
                response.fecha_registro = response.documento.fecha_registro;
                response.serie = response.documento.serie;
                response.numero = response.documento.numero;
                response.descripcion = response.documento.descripcion;
                response.monto = response.documento.monto;
                response.totalAbono = response.totalA;
                response.proveedor = response.documento.proveedor
                response.proveedorN = response.documento.proveedorN;
                response.diferencia = parseFloat(response.documento.monto) - parseFloat(response.totalA)
                localStorage.setItem('idPolizaAbonoPro', response.documento.poliza);
                const poliza = localStorage.getItem('idPolizaAbonoPro');
                localStorage.setItem('detalleID_AbonoPro', response.detalle);
                dispatch(initializeForm('FormAbonoProveedor', response));
                dispatch(leerDetalleDocumentos(response.detalle));
                if (poliza) {
                    dispatch(leerDetalleMod(poliza));
                }

                NotificationManager.success(
                    'Abono modificado correctamente',
                    'Exito',
                    3000
                );
                let ruta = `/compras/${id_emp[5]}/abonos/${id_emp[7]}`
                dispatch(push(ruta));
            }).catch((error) => {
                localStorage.setItem('conf_Abono','F');
                NotificationManager.error(
                    error.msj,
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }else{
            localStorage.setItem('conf_Abono','F')
            Swal.fire({
                title: 'Abono de proveedor',
                text: `Abono con diferencia: ${new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(data.diferencia)}`,
                type: 'error',
            })    
        }
    }else {
        localStorage.setItem('conf_Abono','F')
        Swal.fire({
            title: 'Abono de proveedor',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })    
    }
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/abono_cxp/${id}`).then((response) => {
        response.fecha_registro = response.fecha_registro;
        response.proveedor = response.proveedor;
        response.diferencia = parseFloat(response.monto)-parseFloat(response.totalDetalleAbono);
        response.totalAbono = parseFloat(response.totalDetalleAbono);
        localStorage.setItem('detalleID_AbonoPro', response.id);
        const editar = window.location.href.includes('editar');
        if (editar) {
            localStorage.setItem('idPolizaAbonoPro', response.poliza);
            dispatch({ type: GUARDAR_ABONO, lecturaA: response });
            dispatch(leerDetalleDocumentos(response.id));
            dispatch(initializeForm('FormAbonoProveedor', response));
            dispatch(leerDetalleMod(response.poliza));
        }else{
            dispatch({ type: GUARDAR_ABONO, lecturaA: response });
            dispatch(leerDetalleDocumentos(response.id));
            dispatch(initializeForm('FormAbonoProveedor', response));
            dispatch(leerDetalleMod(response.poliza));
        }

    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar abono',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const registroPolizaDetalle = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.AbonoProveedorFormTabs.values;
    const poliza = localStorage.getItem('idPolizaAbonoPro');
    const formData = {
        empresa: id_emp[5],
        periodo: id_emp[7],
        cc: data.cc.value,
        cuenta: data.cuenta.value,
        debe: data.debe,
        haber: data.haber,
        poliza: poliza
    }
    if (formData.debe === undefined && formData.haber === undefined) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'Debe de ingresar valor en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) === 0.00 && parseFloat(formData.haber) === 0.00) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores a 0.00 en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01 && parseFloat(formData.haber) >= 0.01) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores en debe y haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01) {
        formData.haber = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            const poliza = localStorage.getItem('idPolizaAbonoPro')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('AbonoProveedorFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('AbonoProveedorFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('AbonoProveedorFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }
        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                3000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else if (parseFloat(formData.haber) >= 0.01) {
        formData.debe = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            localStorage.removeItem('tipo_poliza');
            const poliza = localStorage.getItem('idPolizaAbonoPro')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('AbonoProveedorFormTabs', response));
                dispatch(leerDetalle(response.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('AbonoProveedorFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('AbonoProveedorFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                3000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const registroPolizaDetalleSCC = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.AbonoProveedorFormTabs.values;
    const poliza = localStorage.getItem('idPolizaAbonoPro');
    const formData = {
        empresa: id_emp[5],
        periodo: id_emp[7],
        cc: 0,
        cuenta: data.cuenta.value,
        debe: data.debe,
        haber: data.haber,
        poliza: poliza
    }
    if (formData.debe === undefined && formData.haber === undefined) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'Debe de ingresar valor en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) === 0.00 && parseFloat(formData.haber) === 0.00) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores a 0.00 en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01 && parseFloat(formData.haber) >= 0.01) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores en debe y haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01) {
        formData.haber = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            const poliza = localStorage.getItem('idPolizaAbonoPro')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('AbonoProveedorFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('AbonoProveedorFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('AbonoProveedorFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                3000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else if (parseFloat(formData.haber) >= 0.01) {
        formData.debe = 0.00;
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            localStorage.removeItem('tipo_poliza');
            const poliza = localStorage.getItem('idPolizaAbonoPro')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('AbonoProveedorFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('AbonoProveedorFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('AbonoProveedorFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                3000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const confirmarPoliza = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let dif = localStorage.getItem('dif');
    if (parseFloat(dif) === 0.00) {
        localStorage.removeItem('idPolizaAbonoPro');
        localStorage.removeItem('dif');
        NotificationManager.success(
            'Póliza guardada correctamente',
            'Exito',
            3000
        );
        const editar = window.location.href.includes('editar');
        if (editar) {
            let ruta = `/compras/${id_emp[5]}/abonos/${id_emp[7]}`
            dispatch(push(ruta));
        } else {
            window.location.reload(true);
        }

    } else {
        Swal.fire({
            title: 'Modificar poliza',
            text: `Poliza con diferencia: ${new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(dif)}`,
            type: 'error',
        })
    }
}

const eliminarAbono = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`abono_cxp/${id}`).then(() => {
        dispatch(listar(1));
        NotificationManager.success(
            'Abono borrado correctamente',
            'Exito',
            3000
        );
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar abono',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const leerDocumentos = (id, fecha_fin, moneda) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idEmpre = id_emp[5];
    let fechaF = fecha_fin;
    const params = { id, idEmpre, fechaF, moneda }
    dispatch(setLoaderC(true));
    api.get('/compra/documentos_proveedor/', params).then((response) => {
        const saldos = response.detalle.filter((item) => parseFloat(item.total) >= 0.01);
        dispatch({ type: LISTADO_DOCUMENTOS, doc: saldos });
        const documento = [];
        response.detalle.forEach(item => {
            if (parseFloat(item.total) >= 0.01) {
                documento.push({
                    value: item.id + ' # ' + item.total + ' # ' + item.tipo + ' # ' + item.fecha + ' # ' + item.serie + ' # ' + item.numero + '#' + item.id_factura, 
                    label: moment(item.fecha).format("DD/MM/YYYY") + ' --- ' + item.serie + ' / ' + item.numero + ' -- ' + ' Saldo: ' + new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(item.total),
                })
            }
        })
        dispatch({ type: DOCUMENTOS, list_doc: documento })
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar documentos del proveedor',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });

}

export const listarMonedas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/monedas/moneda_empresa', params).then((response) => {
        const moneda = [];
        response.forEach(item => {
            moneda.push({
                value: item.id,
                label: item.moneda + ' - ' + item.simbolo
            })
        })
        dispatch({ type: LISTADO_MONEDAS, monedas: moneda });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar monedas',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const leerDetalleDocumentos = id => (dispatch) => {
    api.get(`/abono_cxp_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_DOCUMENTOS, detalleDoc: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const eliminarDocumento = id => (dispatch) => {
    const detalle = localStorage.getItem('detalleID_AbonoPro');
    dispatch(setLoader(true));
    api.eliminar(`/abono_cxp_detalle/${id}`).then((response) => {
        if (detalle) {
            const poliza = localStorage.getItem('idPolizaAbonoPro');
            const editar = window.location.href.includes('editar');
            response.fecha_registro = response.documento.fecha_registro;
            response.serie = response.documento.serie;
            response.numero = response.documento.numero;
            response.descripcion = response.documento.descripcion;
            response.monto = response.documento.monto;
            response.totalAbono = response.totalA;
            response.proveedor = response.documento.proveedor;
            response.proveedorN = response.documento.proveedorN;
            response.tipo_cambio = response.documento.tipo_cambio;
            response.moneda = response.documento.moneda;
            response.id = response.documento.id;
            response.diferencia = parseFloat(response.documento.monto) - parseFloat(response.totalA)
            dispatch(initializeForm('FormAbonoProveedor', response));
            dispatch(leerDetalleDocumentos(detalle));
            if (!!poliza === undefined) {
                dispatch(leerDetalle(poliza));
            }
            if (editar) {
                dispatch(leerDetalleDocumentos(detalle));
                dispatch(initializeForm('FormAbonoProveedor', response));
            } else {
                dispatch(leerDetalleDocumentos(detalle));
                dispatch(initializeForm('FormAbonoCliente', response));
            }
        }
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar el documento',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id }
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({ type: PARAMETRO, datos: response });
        api.get('/empresa/empresa_impuesto', params).then((imp) => {
            if (imp.validar === 'false'){
                Swal.fire({
                    title: 'Abono de proveedor',
                    text: imp.msj,
                    type: 'info',
                    allowOutsideClick: false,
                    confirmButtonText: "Continuar",
                }).then((result) => {
                    if (result.value) {
                        let ruta = `/ajustes/${id_emp[5]}/impuestos`
                        dispatch(push(ruta));
                    }
                });
            }
            if (imp.validar === 'true'){
                api.get('/empresa/empresa_configuracion_poliza', params).then((datos) => {
                    if (datos.validar === 'false'){
                        Swal.fire({
                            title: 'Abono de proveedor',
                            text: datos.msj,
                            type: 'info',
                            allowOutsideClick: false,
                            confirmButtonText: "Continuar",
                        }).then((result) => {
                            if (result.value) {
                                let ruta = `/contabilidad/${id_emp[5]}/config`
                                dispatch(push(ruta));
                            }
                        });
                    }
                    
                }).catch((error)=>{
                    
                    NotificationManager.error(
                        'Ocurrió un error al consultar impuesto',
                        'Error',
                        8000
                    );
                })
            }
            
        }).catch((error)=>{
            
            NotificationManager.error(
                'Ocurrió un error al consultar parametros',
                'Error',
                8000
            );
        })
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

let detalle = [];
export const agregarLinea = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormAbonoProveedor.values;

    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha_registro);

    if (fecha >= fechaI && fecha <= fechaF) {
        let docu = data.documento.value;
        let documento = docu.split('#');
        let saldo = parseFloat(documento[1].trim());
        let abono = parseFloat(data.abono);
        let totAbono = 0.00;
        if (data.monto === undefined){
            totAbono = 0.00
        }else{
            totAbono = parseFloat(data.monto);
        }
        //let GtotalAbono = parseFloat(data.totalDetalleAbono)+parseFloat(data.abono);
        let dif = parseFloat(data.diferencia);
        let totDoc = 0.00;

        let pro = data.proveedor_documento;
        if (pro === undefined) {
            pro = data.proveedor;
        } else {
            pro = data.proveedor_documento.value;
        }
        if (totAbono === 0.00){
            Swal.fire({
                title: 'Abono de proveedor',
                text: 'Monto del documento no puede ser 0.00',
                type: 'error',
            })
        }else{
            if (abono > totAbono) {
                Swal.fire({
                    title: 'Abono de proveedor',
                    text: 'El abono no puede ser mayor al monto del documento',
                    type: 'error',
                })
            } else {
                if (abono === 0 || data.abono === undefined) {
                    Swal.fire({
                        title: 'Abono de proveedor',
                        text: 'El abono no puede ser 0.00',
                        type: 'error',
                    })
                } else {
                    if (abono > saldo) {
                        Swal.fire({
                            title: 'Abono de proveedor',
                            text: 'El abono es mayor al saldo del documento',
                            type: 'error',
                        })
                    } else {
                        if (detalle.length > 0) {
                            let dato = '';
                            if (documento[2].trim() === 'IMP') {
                                dato = detalle.find(item => item.id_factura_imp === documento[6].trim());
                            }else{
                                dato = detalle.find(item => item.id === documento[0].trim());
                            }
                            if (dato) {
                                NotificationManager.error(
                                    'Documento ya fue ingresado',
                                    'Error',
                                    3000
                                );
                            } else {
                                if (abono > dif) {
                                    Swal.fire({
                                        title: 'Abono de proveedor',
                                        text: 'El abono es mayor a la diferencia',
                                        type: 'error',
                                    })
                                } else {
                                    detalle.push({
                                        id: documento[0].trim(),
                                        td: documento[2].trim(),
                                        id_factura_imp: documento[6].trim(),
                                        abono: data.abono,
                                        fecha: documento[3].trim(),
                                        serie: documento[4].trim(),
                                        numero: documento[5].trim(),
                                        monto: documento[1].trim(),
                                        saldo: parseFloat(documento[1] - parseFloat(data.abono)).toFixed(2)
                                    })
                                    detalle.forEach(item => totDoc += parseFloat(item.abono));
                                    data.totalAbono = parseFloat(totDoc);
                                    data.diferencia = parseFloat(parseFloat(totAbono - parseFloat(totDoc))).toFixed(2);
                                    data.detalleD = detalle;
                                    data.abono = 0;
                                    NotificationManager.success(
                                        'Documento ingresado correctamente',
                                        'Exito',
                                        3000
                                    );
                                    dispatch(actualizar());
                                }
                            }
                        } else {
                            detalle.push({
                                id: documento[0].trim(),
                                td: documento[2].trim(),
                                id_factura_imp: documento[6].trim(),
                                abono: data.abono,
                                fecha: documento[3].trim(),
                                serie: documento[4].trim(),
                                numero: documento[5].trim(),
                                monto: documento[1].trim(),
                                saldo: parseFloat(documento[1] - parseFloat(data.abono)).toFixed(2)
                            })
                            detalle.forEach(item => totDoc += parseFloat(item.abono));
                            data.totalAbono = parseFloat(totDoc);
                            data.diferencia = parseFloat(parseFloat(totAbono - parseFloat(totDoc))).toFixed(2);
                            data.detalleD = detalle;
                            data.abono = 0;
                            NotificationManager.success(
                                'Documento ingresado correctamente',
                                'Exito',
                                3000
                            );
                            dispatch(actualizar());
                        }

                    }
                }
            }
        }
    }else {
        Swal.fire({
            title: 'Abono de proveedor',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

const eliminarLineaAbonoNuevo = (id) => (dispatch, getStore) => {
    const data = getStore().form.FormAbonoProveedor.values;
    let totAbono = parseFloat(data.monto);
    let totDoc = 0.00;
    
    detalle.forEach((element, index) => {
        if (element.id === id) {
            detalle.splice(index, 1);
        }
    })
    detalle.forEach(item => totDoc += parseFloat(item.abono));
    data.totalAbono = parseFloat(totDoc);
    data.diferencia = parseFloat(totAbono - parseFloat(totDoc));
    data.detalleD = detalle;
    dispatch(actualizar());
};

export const nuevaLineaDocumento = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormAbonoProveedor.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const detalle = localStorage.getItem('detalleID_AbonoPro');
    
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha_registro);
    
    if (fecha >= fechaI && fecha <= fechaF) {
        let docu = data.documento.value;
        let documento = docu.split('#');

        let saldo = parseFloat(documento[1].trim());
        let abono = parseFloat(data.abono);
        let GtotalAbono = parseFloat(data.totalDetalleAbono)+parseFloat(data.abono);
        let totAbono = parseFloat(data.monto);
                
        let pro = data.proveedor_documento;
        if (pro === undefined){
            pro = data.proveedor;
        }else{
            pro = data.proveedor_documento.value;
        }
        if (GtotalAbono > totAbono) {
            Swal.fire({
                title: 'Abono de proveedor',
                text: 'El abono no puede ser mayor al monto del documento',
                type: 'error',
            })
        } else {
            if (abono === 0 || data.abono === undefined) {
                Swal.fire({
                    title: 'Abono de proveedor',
                    text: 'El abono no puede ser 0.00',
                    type: 'error',
                })
            } else {
                if (abono > saldo) {
                    Swal.fire({
                        title: 'Abono de proveedor',
                        text: 'El abono es mayor al saldo del documento',
                        type: 'error',
                    })
                } else {
                    const formData = {
                        fecha_registro: data.fecha_registro,
                        serie: data.serie,
                        numero: data.numero,
                        monto: parseFloat(data.monto),
                        descripcion: data.descripcion,
                        tipo_cambio: data.tipo_cambio,
                        tipo_cargo: data.tipo,
                        moneda: data.moneda,
                        periodo: id_emp[7],
                        tipo: 'ABONO',
                        td: documento[2].trim(),
                        idDoc: documento[0].trim(),
                        abono: parseFloat(data.abono),
                        saldo: parseFloat(documento[1].trim())-parseFloat(data.abono),
                        empresa: id_emp[5],
                        poliza: 'abonos',
                        detalle: detalle,
                        proveedor: pro,
                        modulo: 'CMP',
                        idPoliza: localStorage.getItem('idPolizaAbonoPro')
                    }
                    dispatch(setLoader(true));
                    api.post('/abono_cxp/', formData).then((response) => {
                        if (detalle){
                            response.fecha_registro = response.documento.fecha_registro;
                            response.serie = response.documento.serie;
                            response.numero = response.documento.numero;
                            response.descripcion = response.documento.descripcion;
                            response.monto = response.documento.monto;
                            response.totalAbono = response.totalA;
                            response.proveedor = response.documento.proveedor
                            response.proveedorN = response.documento.proveedorN;
                            response.id = response.documento.id;
                            response.tipo_cambio = response.documento.tipo_cambio;
                            response.moneda = response.documento.moneda;
                            response.diferencia = parseFloat(response.documento.monto)-parseFloat(response.totalA)
                            dispatch(initializeForm('FormAbonoProveedor', response));
                            dispatch(leerDetalleDocumentos(response.detalle));
                            if (response.documento.poliza) {
                                const poliza = localStorage.getItem('idPolizaAbonoPro');
                                dispatch(leerDetalleMod(poliza));
                            }
                            dispatch(leerDocumentos(response.proveedor, response.fecha_registro, response.documento.moneda));
                        }else{
                            response.fecha_registro = response.documento.fecha_registro;
                            response.serie = response.documento.serie;
                            response.numero = response.documento.numero;
                            response.descripcion = response.documento.descripcion;
                            response.monto = response.documento.monto;
                            response.totalAbono = response.totalA;
                            response.proveedor = response.documento.proveedor
                            response.proveedorN = response.documento.proveedorN;
                            response.id = response.documento.id;
                            response.moneda = response.documento.moneda;
                            response.diferencia = parseFloat(response.documento.monto)-parseFloat(response.totalA)
                            localStorage.setItem('idPolizaAbonoPro', response.documento.poliza);
                            localStorage.setItem('detalleID_AbonoPro', response.detalle);
                            dispatch(initializeForm('FormAbonoProveedor', response));
                            dispatch(leerDetalleDocumentos(response.detalle));
                            if (response.documento.poliza) {
                                const poliza = localStorage.getItem('idPolizaAbonoPro');
                                dispatch(leerDetalle(poliza));
                            }
                            dispatch(leerDocumentos(response.proveedor, response.fecha_registro, response.documento.moneda));
                        }
                        NotificationManager.success(
                            'Documento agregado correctamente',
                            'Exito',
                            3000
                        );
                    }).catch((error) => {
                        NotificationManager.error(
                            error.msj,
                            'Error',
                            0
                        );
                    }).finally(() => {
                        dispatch(setLoader(false));
                    });
                }
            }
        }
    }else{
        Swal.fire({
            title: 'Abono de proveedor',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

const vaciar = () => (dispatch) => {
    detalle = [];
    dispatch(initializeForm('FormAbonoProveedor'));
    dispatch({ type: DETALLE, detalleDocN: detalle });
};

const actualizar = () => (dispatch, getStore) => {
    const data = getStore().form.FormAbonoProveedor.values;
    dispatch(initializeForm('FormAbonoProveedor', data));
    dispatch({ type: DETALLE, detalleDocN: data.detalleD });
};

export const tipoCambio = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormAbonoProveedor.values;
    
    if (data === undefined) {
        Swal.fire({
            title: 'Abono de proveedor',
            text: 'Debe de ingresar datos de abono de proveedor',
            type: 'error',
        })
    } else {
        let fechaI = moment(periodoD.fecha_inicio);
        let fechaF = moment(periodoD.fecha_fin);
        let fecha = moment(data.fecha_registro)
        if (fecha >= fechaI && fecha <= fechaF) {
            if (data.fecha_registro === undefined) {
                Swal.fire({
                    title: 'Abono de proveedor',
                    text: 'Debe de ingresar fecha del abono de proveedor',
                    type: 'error',
                })
            } else {
                let fecha = data.fecha_registro;
                let ruta = window.location.href;
                let id_emp = ruta.split('/');
                let idEmpre = id_emp[5];
                const params = { fecha, idEmpre }
                dispatch(setLoaderC(true));
                return api.get('/tipo_cambio/tipo_cambio_fecha/', params).then((response) => {
                    if (parseFloat(response) === 0) {
                        Swal.fire({
                            title: 'No hay tipo de cambio del dia' + ' ' + moment(fecha).format("DD/MM/YYYY"),
                            html:
                                '<div class="col-sm-12 mt-4">' +
                                '<h4 style="color: white;">¿Desea ingresarlo?</h4>' +
                                '</div>',
                            type: 'info',
                            background: "black",
                            showCancelButton: true,
                            confirmButtonText: 'Si',
                            cancelButtonText: 'No',
                            confirmButtonColor: "#4040ff",
                            cancelButtonColor: "#646464",
                            reverseButtons: true,
                            customClass: {
                                title: 'sweet_titleImportant',
                            },
                        }).then((result) => {
                            if (result.value) {
                                Swal.fire({
                                    title: 'Ingrese tipo de cambio',
                                    html:
                                        '<div class="col-sm-12 mt-3">' +
                                        '<h4 style="color: white">' + moment(fecha).format("DD/MM/YYYY") + '</h4>' +
                                        '<input type="number" min="1" id="cambio" class="swal2-input form-control">' +
                                        '</div>',
                                    type: 'info',
                                    background: "black",
                                    showCancelButton: true,
                                    confirmButtonText: 'Si',
                                    cancelButtonText: 'No',
                                    confirmButtonColor: "#4040ff",
                                    cancelButtonColor: "#646464",
                                    reverseButtons: true,
                                    customClass: {
                                        title: 'sweet_titleImportant',
                                    },
                                }).then((result) => {
                                    if (result.value) {
                                        let tcambio = document.getElementById('cambio').value
                                        const formData = {
                                            fechaT: fecha,
                                            tipoCambio: tcambio,
                                            empresa: id_emp[5],
                                            tipo: 'manual'
                                        }
                                        api.post('/tipo_cambio/', formData).then(response => {
                                            NotificationManager.success(
                                                'Tipo de cambio guardado',
                                                'Exito',
                                                4000
                                            );

                                        }).catch((error) => {
                                            NotificationManager.error(
                                                error.msj,
                                                'Error',
                                                0
                                            );
                                        })
                                    }
                                });
                            }
                        });
                    } else {
                        return response;
                    }
                }).catch((error) => {
                    NotificationManager.error(
                        'Ocurrió un error al consultar tipo de cambio',
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoaderC(false));
                });
            }
        } else {
            Swal.fire({
                title: 'Abono de proveedor',
                text: 'Fecha fuera del periodo',
                type: 'error',
            })
        }
    }
}

const limpiarDocumentos = () => (dispatch) => {
    dispatch({ type: LISTADO_DOCUMENTOS, doc: [] });
    dispatch({ type: DOCUMENTOS, list_doc: [] })
};

export const actions = {
    listar,
    registroAbono,
    modificarAbono,
    leer,
    listarTiposDocu,
    listarProveedores,
    leerDetalle,
    eliminarLinea,
    listarCuentas,
    listarCC,
    listarNiveles,
    registroCuenta,
    reporteCuentas,
    registroPolizaDetalle,
    confirmarPoliza,
    registroPolizaDetalleSCC,
    eliminarAbono,
    leerDocumentos,
    listarMonedas,
    leerDetalleDocumentos,
    eliminarDocumento,
    leerPeriodoD,
    getModulo,
    parametrosEmpresa,
    agregarLinea,
    eliminarLineaAbonoNuevo,
    nuevaLineaDocumento,
    vaciar,
    actualizar,
    tipoCambio,
    limpiarDocumentos
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_ABONO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DETALLE_POLIZA]: (state, { detalle }) => {
        return {
            ...state,
            detalle,
        };
    },
    [LISTADO_TIPOS_DOCU]: (state, { tipos }) => {
        return {
            ...state,
            tipos,
        };
    },
    [LISTADO_PROVEEDOR]: (state, { pro }) => {
        return {
            ...state,
            pro,
        };
    },
    [NIVEL]: (state, { nivel }) => {
        return {
            ...state,
            nivel,
        };
    },
    [LOADERC]: (state, { loaderC }) => {
        return {
            ...state,
            loaderC,
        };
    },
    [GUARDAR_POLIZA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [GUARDAR_ABONO]: (state, { lecturaA }) => {
        return {
            ...state,
            lecturaA,
        };
    },
    [LISTADO_DOCUMENTOS]: (state, { doc }) => {
        return {
            ...state,
            doc,
        };
    },
    [LISTADO_MONEDAS]: (state, { monedas }) => {
        return {
            ...state,
            monedas,
        };
    },
    [DETALLE_DOCUMENTOS]: (state, { detalleDoc }) => {
        return {
            ...state,
            detalleDoc,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [DOCUMENTOS]: (state, { list_doc }) => {
        return {
            ...state,
            list_doc,
        };
    },
    [DETALLE]: (state, { detalleDocN }) => {
        return {
            ...state,
            detalleDocN,
        };
    },
};

export const initialState = {
    loader: false,
    tipos: [],
    pro: [],
    lectura: {
        polizaDetalle_polizaEncabezado: []
    },
    lecturaA: [],
    doc: [],
    periodoS: null,
    detalleDoc: [],
    nivel: [],
    monedas: [],
    loaderC: false,
    detalle: [],
    detalleDocN: [],
    listaEs: [],
    modulo: [],
    list_doc: [],
    periodoD: [],
    datos: [],
    data: [],
    page: 1,
};

export default handleActions(reducers, initialState)