
import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import moment from 'moment';
import Swal from 'sweetalert2';

const LOADER = 'LOADER';
const LISTADO_PERIODOS = 'LISTADO_PERIODOS';
const PERIODOS_LIST = 'PERIODOS_LIST';
const GUARDAR_PERIODO = 'GUARDAR_PERIODO';
const LISTADO_CUENTAS = 'LISTADO_CUENTAS';
const LISTADO_ESTABLECIMIENTOS = 'LISTADO_ESTABLECIMIENTOS';
const MODULO = 'MODULO';
const PARAMETRO = 'PARAMETRO';

const valEmp = __CONFIG__.empresa;

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listarPeriodos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id };
    }else{
        id = id_emp[5];
        params = { id };
    }
    dispatch(setLoader(true));
    api.get('/periodos/periodo_empresa', params).then((response)=>{
        dispatch({ type: LISTADO_PERIODOS, periodos: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar los periodos',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/periodos/${id}`).then((response) => {
        let mes = response.periodo.split(' ');
        response.mes = String(mes[0]);
        response.anio = mes[1];
        response.fecha_inicial = moment(response.fechaInicio).format('DD/MM/YYYY');
        response.fecha_fin = moment(response.fechaFin).format('DD/MM/YYYY');
        dispatch({type: GUARDAR_PERIODO, lectura: response });
        dispatch(initializeForm('FormPeriodos', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar el periodo',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroPeriodo = () => (dispatch, getStore) => {
    const data = getStore().form.FormPeriodos.values;
    let estatus = 'A';
    if (data.estado === 'C'){
        estatus = 'C';
    }
    let per = JSON.parse(localStorage.getItem('periodo'));
    let fec = data.anio + '-' + per.mes;
    let firstdate = moment(fec).startOf('month').format('YYYY-MM-DD');
    let lastdate = moment(fec).endOf('month').format("YYYY-MM-DD");
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id_empresa = '';
    if (valEmp === 'fage'){
        id_empresa = id_emp[6]
    }else{
        id_empresa = id_emp[5]
    }
    const formData = {
        periodo: per.value+" "+data.anio,
        fecha_inicio: firstdate,
        fecha_fin: lastdate,
        empresa: id_empresa,
        estado: estatus
    }
    dispatch(setLoader(true));
    api.post('/periodos/', formData).then(() => {
        NotificationManager.success(
            'Periodo creado',
            'Exito',
            3000
        );
        localStorage.removeItem('periodo');
        if (id_emp[4] === 'inventario'){
            let ruta = `/inventario/${id_emp[5]}/periodos`;
            dispatch(push(ruta));
        }
        if (id_emp[4] === 'bancos'){
            let ruta = `/bancos/${id_emp[5]}/periodos`;
            dispatch(push(ruta));
        }
        if (id_emp[4] === 'facturacion'){
            let ruta = `/facturacion/${id_emp[5]}/periodos`;
            dispatch(push(ruta));
        }
        if (id_emp[4] === 'compras'){
            let ruta = `/compras/${id_emp[5]}/periodos`;
            dispatch(push(ruta));
        }
        if (id_emp[4] === 'contabilidad'){
            let ruta = `/contabilidad/${id_emp[5]}/periodos`;
            dispatch(push(ruta));
        }
        if (id_emp[4] === 'ajustes'){
            let ruta = `/ajustes/${id_emp[5]}/periodos`;
            dispatch(push(ruta));
        }
        if (id_emp[5] === 'nomina' && valEmp === 'fage'){
            let ruta = `/lfg/nomina/${id_emp[6]}/periodos`;
            dispatch(push(ruta));
        }
        if (id_emp[4] === 'nomina' && valEmp === ''){
            let ruta = `/nomina/${id_emp[5]}/periodos`;
            dispatch(push(ruta));
        }
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarPeriodo = () => (dispatch, getStore) => {
    const datos = getStore().form.FormPeriodos.values;
    const id = datos.id;
    let estatus = 'A';
    if (datos.estado === 'C'){
        estatus = 'C';
    }
    const formData = {
        id: id,
        estado: estatus
    }
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    dispatch(setLoader(true));
    api.put(`/periodos/${id}/`, formData).then((response) => {
        NotificationManager.success(
            'Periodo modificado correctamente',
            'Exito',
            3000
        );
        if (id_emp[4] === 'inventario'){
            let ruta = `/inventario/${id_emp[5]}/periodos`;
            dispatch(push(ruta));
        }
        if (id_emp[4] === 'bancos'){
            let ruta = `/bancos/${id_emp[5]}/periodos`;
            dispatch(push(ruta));
        }
        if (id_emp[4] === 'facturacion'){
            let ruta = `/facturacion/${id_emp[5]}/periodos`;
            dispatch(push(ruta));
        }
        if (id_emp[4] === 'compras'){
            let ruta = `/compras/${id_emp[5]}/periodos`;
            dispatch(push(ruta));
        }
        if (id_emp[4] === 'contabilidad'){
            let ruta = `/contabilidad/${id_emp[5]}/periodos`;
            dispatch(push(ruta));
        }
        if (id_emp[4] === 'ajustes'){
            let ruta = `/ajustes/${id_emp[5]}/periodos`;
            dispatch(push(ruta));
        }
        if (id_emp[5] === 'nomina' && valEmp === 'fage'){
            let ruta = `/lfg/nomina/${id_emp[6]}/periodos`;
            dispatch(push(ruta));
        }
        if (id_emp[4] === 'nomina' && valEmp === ''){
            let ruta = `/nomina/${id_emp[5]}/periodos`;
            dispatch(push(ruta));
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar el periodo',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const periodosList = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
    }else{
        id = id_emp[5];
    }
    return api.get(`/periodos/periodo_empresa/?id=${id}`).then((data)=>{
        const periodo = [];
        data.forEach(item=>{
            periodo.push({
                value: item.id,
                label: item.periodo
            })
        })
        dispatch({ type: PERIODOS_LIST, periodoL: periodo });
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al listar los periodos',
            'Error',
            0
        );
    })
}

export const listarCuentasBancarias = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/cuenta_banco', params).then((response) => {
        const cuentas = [];
        response.forEach(item => {
            cuentas.push({
                value: item.id,
                label: item.numero_cuenta + ' - ' + item.monedaN + ' - ' + item.tipo + ' [ ' + item.bancoNombre + ' ]'
            })
        })
        dispatch({ type: LISTADO_CUENTAS, cuenta: cuentas });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar cuentas bancarias',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leerCuentaB = (cuenta_bancaria) => () => {
    api.get(`/cuenta_banco/${cuenta_bancaria}`).then((response) => {
        localStorage.setItem('cuentaBan', response.numero_cuenta)
        localStorage.setItem('ban', response.bancoNombre)
        localStorage.setItem('monedaCB', response.monedaN)
        localStorage.setItem('tipoCB', response.tipo)
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        let id = id_emp[5];
        let cuenta = cuenta_bancaria;
        let params = {id, cuenta}
        api.get('/egresos/numero_cheque/', params).then((response) => {
            localStorage.setItem('ck',parseInt(response.detalle)+1)
            localStorage.setItem('numeroCheque',parseInt(response.detalle)+1)
        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al consultar correlativo',
                'Error',
                0
            );
        })
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar Cuenta Bancaria',
            'Error',
            0
        );
    })
}

const listarEstablecimientos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    return api.get('/sucursal', params).then((data)=>{
        if (data.results.length === 0){
            Swal.fire({
                title: 'Factura',
                text: 'No hay establecimiento creado',
                type: 'info',
                allowOutsideClick: false,
                confirmButtonText: "Continuar",
            }).then((result) => {
                if (result.value) {
                    let ruta = `/ajustes/${id_emp[5]}/establecimientos`
                    dispatch(push(ruta));
                }
            });
        }else{
            const estable = [];
            data.results.forEach(item=>{
                estable.push({
                    value: item.id,
                    label: item.codigo + ' - ' + item.nombre_comercial
                })
            })

            if (estable.length === 1 ){
                estable.forEach(item => {
                    localStorage.setItem('id_establecimiento', item.value);
                })
            }
            dispatch({ type: LISTADO_ESTABLECIMIENTOS, listaEs: estable });
        }
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al listar establecimientos',
            'Error',
            0
        );
    })
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id}
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({type: PARAMETRO, datos: response });
    }).catch((error)=>{
        
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const actions = {
    registroPeriodo,
    modificarPeriodo,
    listarPeriodos,
    periodosList,
    listarCuentasBancarias,
    leer,
    leerCuentaB,
    listarEstablecimientos,
    getModulo,
    parametrosEmpresa
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_PERIODOS]: (state, { periodos }) => {
        return {
            ...state,
            periodos,
        };
    },
    [PERIODOS_LIST]: (state, { periodoL }) => {
        return {
            ...state,
            periodoL,
        };
    },
    [GUARDAR_PERIODO]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [LISTADO_CUENTAS]: (state, { cuenta }) => {
        return {
            ...state,
            cuenta,
        };
    },
    [LISTADO_ESTABLECIMIENTOS]: (state, { listaEs }) => {
        return {
            ...state,
            listaEs,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
};

export const initialState = {
    loader: false,
    periodos: null,
    lectura: [],
    listaEs: [],
    periodoL: [],
    modulo: [],
    datos: [],
};

export default handleActions(reducers, initialState)