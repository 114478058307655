import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BootstrapTable, SearchField } from 'react-bootstrap-table';
import LoadMask from "../LoadMask/LoadMask";


export default class Grid extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    createCustomClearButton = (onClick) => {
        return (
          <button className='btn btn-dark btn-sm ml-2' onClick={ onClick }>Limpiar</button>
        );
    }

    createCustomSearchField = () => {
        return (
            <SearchField
                className='form-floating'
                placeholder='Buscar...'
                style={{"height": "35px","font-size": "100%"}}
            />
        );
    }

    renderPaginationTotal = (to, size, total) => {
        return (
            <span style={{"display": "flex","color": 'white',"align-items": "center"}}>
                Mostrando {to} de {size} [ Total de registros: {total} ]
            </span>
        );
    };

    render() {
        const {
            loading,
            data,
            page,
            remote=true,
            expandableRow,
            expandComponent,
            cellEditProp,
            afterSave,
            onPageChange,
            onSortChange,
            pagination,
            expanding,
            onExpand,
            trClassName,
            ...other
        } = this.props;
        
        const options = {
            sizePerPage: 25,
            hideSizePerPage: false,
            sizePerPageList: [ 25, 50, 100 ],
            alwaysShowAllBtns: true,
            clearSearch: true,
            page,
            paginationShowsTotal: this.renderPaginationTotal,
            clearSearchBtn: this.createCustomClearButton,
            searchField: this.createCustomSearchField,
            noDataText: loading ? 'Cargando...' : <span className='title'>No hay datos</span>,
            onPageChange: onPageChange ? onPageChange : () => {},
            onSortChange: onSortChange ? onSortChange : () => {},
            onExpand:onExpand
        };

        const paginar = !(pagination === false);

        return (
            <div>
                <LoadMask loading={loading} dark blur>
                    <BootstrapTable
                        expandableRow={expandableRow}
                        expandComponent={expandComponent}
                        trClassName={trClassName}
                        cellEdit={cellEditProp}
                        data={loading ? [] : data}
                        afterSaveCell={afterSave}
                        pagination={paginar}
                        fetchInfo={{ dataTotalSize: data.count }}
                        searchPlaceholder="Buscar..."
                        search={ true } 
                        multiColumnSearch={ true }
                        options={options}
                        searchTotal={this.props.data.count}
                        {...other}
                    />
                </LoadMask>
            </div>

        )
    }
}
