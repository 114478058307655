import React, { useState } from 'react';
import Modal from '../components/Modal';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LoadMask from "../Utils/LoadMask/LoadMask";
import NavbarModulo from '../components/NavbarModulo';

import {
    renderDayPickerB,
    AsyncSelectField
} from "../Utils/renderField/renderField";

function InformesForm(props) {
    const {
        modulo,
        loader,
        listarProductos,
        reporteKardexValorizado,
        listarBodegas,
        reporteExistencia,
        reporteInventarioFisico,
        excelSalidasDeBodegas,
        reporteSalidasDeBodegas,
        reporteEntradasABodegas,
        datos,
        reporteExistenciaReservaOS
    } = props;

    const [estadoModalKardex, cambiarEstadoModalKardex] = useState(false);
    const [estadoModalExistencia, cambiarEstadoModalExistencia] = useState(false);
    const [estadoModalInventarioFisico, cambiarEstadoModalInventarioFisico] = useState(false);
    const [estadoModalSalidasBodega, cambiarEstadoModalSalidasBodega] = useState(false);
    const [estadoModalEntradasBodega, cambiarEstadoModalEntradasBodega] = useState(false);

    const [estadoExistenciaProReserva, cambiarEstadoModalExistenciaProReserva] = useState(false);
    
    let autoFocus = true;
    let titulo = 'Informes'
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
        
            <div className="container">
                <br/>
                <h4 className='mt-1' style={{ color: "white" }}>Informes</h4>
                <div className="row">
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalKardex(!estadoModalKardex)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/inventario/${id_emp[5]}/informes`}
                                aria-controls="list-home">Kardex valorizado
                            </button>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalExistencia(!estadoModalExistencia)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/inventario/${id_emp[5]}/informes`}
                                aria-controls="list-home">Existencia</a>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalInventarioFisico(!estadoModalInventarioFisico)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/inventario/${id_emp[5]}/informes`}
                                aria-controls="list-home">Inventario fisico</a>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalSalidasBodega(!estadoModalSalidasBodega)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/inventario/${id_emp[5]}/informes`}
                                aria-controls="list-home">Salidas de bodega</a>
                        </div>
                    </div>
                    <div className="col-xxl-3 mt-2">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalEntradasBodega(!estadoModalEntradasBodega)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/inventario/${id_emp[5]}/informes`}
                                aria-controls="list-home">Entradas a bodega</a>
                        </div>
                    </div>
                </div>
                {datos.ordenes_servicio === 'S' &&
                    <div>
                        <br/>
                        <h4 className='mt-1' style={{ color: "white" }}>Ordenes de servicio</h4>
                        <div className="row">
                            <div className="col-xxl-4">
                                <div className="list-group" id="list-tab" role="tablist">
                                    <button class="list-group-item list-group-item-action"
                                        id="list-home-list"
                                        onClick={() => cambiarEstadoModalExistenciaProReserva(!estadoExistenciaProReserva)}
                                        data-bs-toggle="list"
                                        role="tab"
                                        href={`/#/inventario/${id_emp[5]}/informes`}
                                        aria-controls="list-home">Existen de productos en reserva
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <Modal
                    estado={estadoModalKardex} //Kardex
                    cambiarEstado={cambiarEstadoModalKardex}
                    titulo="Kardex valorizado"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="fechaInicial"
                                        component={renderDayPickerB}
                                        msj="Fecha del"
                                    />
                                </div>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaFinal"
                                        component={renderDayPickerB}
                                        msj="Fecha al"
                                    />
                                </div>
                            </div>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="producto"
                                    placeholder="Producto..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarProductos}
                                    msj="Producto"
                                />
                            </div>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="bodega"
                                    placeholder="Bodega..."
                                    className="form-control-find"
                                    loadOptions={listarBodegas}
                                    component={AsyncSelectField}
                                    msj="Bodega"
                                />
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteKardexValorizado}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalKardex(false)}
                                    >
                                    Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>
                <Modal
                    estado={estadoModalExistencia} //Existencia
                    cambiarEstado={cambiarEstadoModalExistencia}
                    titulo="Existencia"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="fechaInicial"
                                        component={renderDayPickerB}
                                        msj="Fecha del"
                                    />
                                </div>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaFinal"
                                        component={renderDayPickerB}
                                        msj="Fecha al"
                                    />
                                </div>
                            </div>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="producto"
                                    placeholder="Producto..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarProductos}
                                    msj="Producto"
                                />
                            </div>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="bodega"
                                    placeholder="Bodega..."
                                    className="form-control-find"
                                    loadOptions={listarBodegas}
                                    component={AsyncSelectField}
                                    msj="Bodega"
                                />
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteExistencia}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalExistencia(false)}
                                    >
                                    Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>  
                <Modal
                    estado={estadoModalInventarioFisico} // Inventario fisico
                    cambiarEstado={cambiarEstadoModalInventarioFisico}
                    titulo="Inventario fisico"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="bodega"
                                    placeholder="Bodega..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarBodegas}
                                    msj="Bodega"
                                />
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteInventarioFisico}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalInventarioFisico(false)}
                                    >
                                    Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>  
                <Modal
                    estado={estadoModalSalidasBodega} // Salidas de bodegas
                    cambiarEstado={cambiarEstadoModalSalidasBodega}
                    titulo="Salidas de bodega"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="fechaInicial"
                                        component={renderDayPickerB}
                                        msj="Fecha del"
                                        />
                                </div>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaFinal"
                                        component={renderDayPickerB}
                                        msj="Fecha al"
                                        />
                                </div>
                            </div>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="bodega"
                                    placeholder="Bodega..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarBodegas}
                                    msj="Bodega"
                                />
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteSalidasDeBodegas}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={excelSalidasDeBodegas}
                                    >
                                    Exportar Excel
                                    </button>
                                </div>
                                <button
                                    className='btn mr-2 mb-3 btn btn-secondary'
                                    onClick={() => cambiarEstadoModalSalidasBodega(false)}
                                    >
                                    Cancelar
                                </button>
                                
                            </Contenido>
                        </LoadMask>
                </Modal>  
                <Modal
                    estado={estadoModalEntradasBodega} // Entradas a bodega
                    cambiarEstado={cambiarEstadoModalEntradasBodega}
                    titulo="Entradas a bodega"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="fechaInicial"
                                        component={renderDayPickerB}
                                        msj="Fecha del"
                                        />
                                </div>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaFinal"
                                        component={renderDayPickerB}
                                        msj="Fecha al"
                                        />
                                </div>
                            </div>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="bodega"
                                    placeholder="Bodega..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarBodegas}
                                    msj="Bodega"
                                />
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteEntradasABodegas}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalEntradasBodega(false)}
                                        >
                                        Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>  
                <Modal
                    estado={estadoExistenciaProReserva} //Existencia
                    cambiarEstado={cambiarEstadoModalExistenciaProReserva}
                    titulo="Existencia productos en reserva"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="fechaInicial"
                                        component={renderDayPickerB}
                                        msj="Fecha del"
                                    />
                                </div>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaFinal"
                                        component={renderDayPickerB}
                                        msj="Fecha al"
                                    />
                                </div>
                            </div>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="producto"
                                    placeholder="Producto..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarProductos}
                                    msj="Producto"
                                />
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteExistenciaReservaOS}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalExistenciaProReserva(false)}
                                    >
                                    Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>  
            </div>
        </div>
    );
}

InformesForm.propTypes = {
    loader: PropTypes.bool,
    modulo: PropTypes.object,
    reporteKardexValorizado: PropTypes.func,
    listarProductos: PropTypes.object,
};

export default reduxForm({
    form: 'FormInformesI',

})(InformesForm);


const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;
