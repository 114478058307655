import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LOADER = 'LOADER';
const LISTADO_MONEDAS = 'LISTADO_MONEDAS';
const MODULO = 'MODULO';

const setLoader = loader => ({
  type: LOADER,
  loader,
});

export const listarCuentas = (search) => () => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const params = { id, search }
  return api.get('/cuentas/empresa_cuentas/', params).then(data => {
    const list_cuentas = [];
    if (data) {
      data.forEach(item => {
        if (item.tipo === 'A') {
          list_cuentas.push({
            value: item.id,
            label: item.cuenta + ' ' + item.nombre,
            isDisabled: true
          })
        } else {
          list_cuentas.push({
            value: item.id,
            label: item.cuenta + ' ' + item.nombre
          })
        }
      })
    }
    return list_cuentas;
  }).catch((error) => {
    console.log("error: ", error);
    return [];
  })
}

export const listarCC = (search) => () => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const params = { id, search }
  return api.get('/centrocosto/empresa_centro_costo/', params).then(data => {
    const list_cc = [];
    if (data) {
      data.forEach(item => {
        list_cc.push({
          value: item.id,
          label: item.codigo + ' ' + item.descripcion
        })
      })
    }
    return list_cc;
  }).catch((error) => {
    console.log("error: ", error);
    return [];
  })
}

function renderSwitch(param) {
  switch (param) {
    case '01':
      return 'ENERO';
    case '02':
      return 'FEBRERO';
    case '03':
      return 'MARZO';
    case '04':
      return 'ABRIL';
    case '05':
      return 'MAYO';
    case '06':
      return 'JUNIO';
    case '07':
      return 'JULIO';
    case '08':
      return 'AGOSTO';
    case '09':
      return 'SEPTIEMBRE';
    case '10':
      return 'OCTUBRE';
    case '11':
      return 'NOVIEMBRE';
    case '12':
      return 'DICIEMBRE';

    default:
      return 'Seleccione un mes';
  }
}

export const reporteLibroVentas = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const data = getStore().form.FormInformesF.values;
  let mes = '';
  if (data === undefined) {
    mes = 'TODAS';
  } else {
    mes = data.mes;
  }
  let mesLetras = renderSwitch(data.mes)
  let folio = localStorage.getItem('folioLV');
  let year = data.year;
  let reporte = 'libroVentas';
  const params = { id, reporte, year, mesLetras, folio };
  dispatch(setLoader(true));
  api.getPdf('/factura', params).then((response) => {
    if (response.headers['content-type'] == 'application/pdf') {
      var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'reporte_libro_ventas.pdf';
      window.open(link, '_blank');
      return false;
    }
  }).catch((error) => {
    if (error.status === 404) {
      NotificationManager.info(
        error.body.msj,
        'Informacion',
        5000
      );
    }
    if (error.status === 500) {
      NotificationManager.error(
        'Ocurrió un error al generar reporte',
        'Error',
        2000
      );
    }

  }).finally(() => {
    dispatch(setLoader(false));
  });
}

export const listarMonedas = () => (dispatch) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const params = { id };
  api.get('/monedas/moneda_empresa', params).then((response) => {
    const moneda = [];
    response.forEach(item => {
      moneda.push({
        value: item.id,
        label: item.moneda + ' - ' + item.simbolo
      })
    })
    dispatch({ type: LISTADO_MONEDAS, monedas: moneda });
  }).catch((error) => {
    NotificationManager.error(
      'Ocurrió un error al listar monedas',
      'Error',
      3000
    );
  }).finally(() => {
    dispatch(setLoader(false));
  });
}


export const reporteAntiguedadSaldos = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const data = getStore().form.FormInformesF.values;
  let fecha = data.fechaFinal;
  let moneda = data.moneda;
  let tipo = data.tipoCliente
  let reporte = 'antiguedad_saldos';
  let cliente = data.cliente ? data.cliente.value : '';
  let detallado = data.detallado ? data.detallado : '';
  const params = { id, reporte, fecha, moneda, tipo, cliente, detallado };
  dispatch(setLoader(true));
  api.getPdf('/factura', params).then((response) => {
    if (response.headers['content-type'] == 'application/pdf') {
      var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'reporte_antiguedad_saldos.pdf';
      window.open(link, '_blank');
      return false;
    }
  }).catch((error) => {
    if (error.status === 404) {
      NotificationManager.info(
        error.body.msj,
        'Informacion',
        5000
      );
    }
    if (error.status === 500) {
      NotificationManager.error(
        'Ocurrió un error al generar reporte',
        'Error',
        0
      );
    }

  }).finally(() => {
    dispatch(setLoader(false));
  });
}

export const listarClientes = (search) => () => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const params = { id, search }
  return api.get('/cliente/empresa_clientes', params).then(data => {
    const list_cliente = [];
    if (data) {
      data.forEach(item => {
        list_cliente.push({
          value: item.id,
          label: item.identificador + ' ' + item.nombre
        })
      })
    }
    return list_cliente;
  }).catch((error) => {
    console.log("error: ", error);
    return [];
  })
}

export const listarListaDePrecios = (search) => () => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const params = { id, search }
  return api.get('/listado_precios', params).then(data => {
    const list_cliente = [];
    if (data) {
      data.results.forEach(item => {
        list_cliente.push({
          value: item.id,
          label: item.nombre_lista
        })
      })
    }
    return list_cliente;
  }).catch((error) => {
    console.log("error: ", error);
    return [];
  })
}

export const getModulo = (id, modulo) => (dispatch) => {
  const params = { id, modulo }
  dispatch(setLoader(true));
  api.get('/modulos/modulo/', params).then((response) => {
    dispatch({ type: MODULO, modulo: response.modulos });
  }).catch(() => {
    NotificationManager.error(
      'Ocurrió un error en buscar modulo',
      'Error',
      2000
    );
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

export const reporteEstadoCuenta = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const data = getStore().form.FormInformesF.values;
  let fechaI = data.fechaInicial;
  let fechaF = data.fechaFinal;
  let moneda = data.moneda;
  let tipo = data.tipoCliente
  let reporte = 'estado_cuenta';
  let cliente = data.cliente ? data.cliente.value : '';
  const params = { id, reporte, fechaI, fechaF, moneda, tipo, cliente };
  dispatch(setLoader(true));
  api.getPdf('/factura', params).then((response) => {
    if (response.headers['content-type'] == 'application/pdf') {
      var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'reporte_antiguedad_saldos.pdf';
      window.open(link, '_blank');
      return false;
    }
  }).catch((error) => {
    if (error.status === 404) {
      NotificationManager.info(
        error.body.msj,
        'Informacion',
        5000
      );
    }
    if (error.status === 500) {
      NotificationManager.error(
        'Ocurrió un error al generar reporte',
        'Error',
        0
      );
    }

  }).finally(() => {
    dispatch(setLoader(false));
  });
}

export const reporteClientes = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const data = getStore().form.FormInformesF.values;
  let tipo_cli = '';
  if (data === undefined) {
    tipo_cli = 'TODOS';
  } else {
    tipo_cli = data.tipo_cliente;
  }
  let reporte = 'reporteClientes';
  const params = { id, reporte, tipo_cli };
  dispatch(setLoader(true));
  api.getPdf('/cliente', params).then((response) => {
    if (response.headers['content-type'] == 'application/pdf') {
      var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'reporte_clientes.pdf';
      window.open(link, '_blank');
      return false;
    }
  }).catch((error) => {
    if (error.status === 404) {
      NotificationManager.info(
        error.body.msj,
        'Información',
        5000
      );
    }
    if (error.status === 500) {
      NotificationManager.error(
        'Ocurrió un error al generar reporte',
        'Error',
        2000
      );
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
}

export const reporteListadoFacturas = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const data = getStore().form.FormInformesF.values;
  let fechaI = data.fechaInicial;
  let fechaF = data.fechaFinal;
  let cliente_id = data.cliente ? data.cliente.value : '';
  let cliente = data.cliente ? data.cliente.label : '';
  let reporte = "listado_facturas"
  let opcion = data.opcion
  const params = { id, reporte, fechaI, fechaF, cliente, cliente_id, opcion };
  dispatch(setLoader(true));
  api.getPdf('/factura', params).then((response) => {
    if (response.headers['content-type'] == 'application/pdf') {
      var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'reporte_listado_facturas.pdf';
      window.open(link, '_blank');
      return false;
    }
  }).catch((error) => {
    if (error.status === 404) {
      NotificationManager.error(
        "No se encontraron datos",
        'Error',
        5000
      );
    }
    if (error.status === 500) {
      NotificationManager.error(
        'Ocurrió un error al generar reporte',
        'Error',
        0
      );
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
}

export const reporteListadoAbonos = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const data = getStore().form.FormInformesF.values;
  let fechaI = data.fechaInicial;
  let fechaF = data.fechaFinal;
  let cliente_id = data.cliente ? data.cliente.value : '';
  let cliente = data.cliente ? data.cliente.label : '';
  let detallado = data.detalladoAb ? data.detalladoAb : '';
  let reporte = "listado_abonos"
  const params = { id, reporte, fechaI, fechaF, cliente, cliente_id, detallado };
  dispatch(setLoader(true));
  api.getPdf('/factura', params).then((response) => {
    if (response.headers['content-type'] == 'application/pdf') {
      var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'reporte_listado_abonos.pdf';
      window.open(link, '_blank');
      return false;
    }
  }).catch((error) => {
    if (error.status === 404) {
      NotificationManager.info(
        "No se encontraron datos",
        'Informacion',
        5000
      );
    }
    if (error.status === 500) {
      NotificationManager.error(
        'Ocurrió un error al generar reporte',
        'Error',
        0
      );
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
}

export const reporteListadoPrecios = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  let data = '';
  data = getStore().form.FormInformesF.values
  if (!data) { data = '' }
  let listaPrecios_id = ''
  let listaPrecios = ''
  let reporte = "listado_precios"
  if (data && data.listaPrecios) {
    listaPrecios_id = data.listaPrecios.value;
    listaPrecios = data.listaPrecios.label;
  }
  const params = { id, reporte, listaPrecios, listaPrecios_id };
  dispatch(setLoader(true));
  api.getPdf('/factura', params).then((response) => {
    if (response.headers['content-type'] == 'application/pdf') {
      var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'reporte_listado_precios.pdf';
      window.open(link, '_blank');
      return false;
    }
  }).catch((error) => {
    if (error.status === 404) {
      NotificationManager.error(
        "No se encontraron datos",
        'Error',
        5000
      );
    }
    if (error.status === 500) {
      NotificationManager.error(
        'Ocurrió un error al generar reporte',
        'Error',
        0
      );
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
}

export const reporteAnticipos = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const data = getStore().form.FormInformesF.values;
  
  let reporte = "anticipos";
  let cliente = data.cliente ? data.cliente.value : '';
  let fecha = data.fechaFinal;
  const params = { id, reporte, cliente, fecha };
  dispatch(setLoader(true));
  api.getPdf('/anticipo_cxc', params).then((response) => {
    if (response.headers['content-type'] == 'application/pdf') {
      var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'reporte_anticipos';
      window.open(link, '_blank');
      return false;
    }
  }).catch((error) => {
    if (error.status === 404) {
      NotificationManager.error(
        "No se encontraron datos",
        'Error',
        5000
      );
    }
    if (error.status === 500) {
      NotificationManager.error(
        'Ocurrió un error al generar reporte',
        'Error',
        0
      );
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
}

export const excelListadoAbonos = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const data = getStore().form.FormInformesF.values;
  let fechaI = data.fechaInicial;
  let fechaF = data.fechaFinal;
  let cliente_id = data.cliente ? data.cliente.value : '';
  let cliente = data.cliente ? data.cliente.label : '';
  let detallado = data.detalladoAb ? data.detalladoAb : '';
  let reporte = "listado_abonos_excel"
  const params = { id, reporte, fechaI, fechaF, cliente, cliente_id, detallado };
  dispatch(setLoader(true));
  api.getExcel('/factura', params).then((response) => {
      const blob = response;
      const fileName = 'listado_abonos.xlsx';

      // Descargar el archivo utilizando la función saveAs de file-saver
      const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(file, fileName);
              
  }).catch((error) => {
    if (error.status === 404){
      NotificationManager.info(
        error.body.msj,
        'Informacion',
        5000
        );
    }
    if (error.status === 500){
      NotificationManager.error(
        'Ocurrió un error al generar reporte',
        'Error',
        0
      );
    }
  }).finally(() => {
      dispatch(setLoader(false));
  });
}

export const excelLibroVentas = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const data = getStore().form.FormInformesF.values;
  let mes = '';
  if (data === undefined) {
    mes = 'TODAS';
  } else {
    mes = data.mes;
  }
  let mesLetras = renderSwitch(data.mes)
  let folio = localStorage.getItem('folioLV');
  let year = data.year;
  let reporte = 'libroVentasExcel';
  const params = { id, reporte, year, mesLetras, folio };
  dispatch(setLoader(true));
  api.getExcel('/factura', params).then((response) => {
    const blob = response;
    const fileName = 'libro_ventas.xlsx';

    // Descargar el archivo utilizando la función saveAs de file-saver
    const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(file, fileName);
                  
  }).catch((error) => {
    if (error.status === 404){
      NotificationManager.info(
        error.body.msj,
        'Informacion',
        5000
       );
    }
    if (error.status === 500){
      NotificationManager.error(
        'Ocurrió un error al generar reporte',
        'Error',
        0
      );
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
}


export const actions = {
  listarCuentas,
  listarCC,
  listarMonedas,
  listarListaDePrecios,
  reporteLibroVentas,
  reporteAntiguedadSaldos,
  listarClientes,
  getModulo,
  reporteEstadoCuenta,
  reporteClientes,
  reporteListadoFacturas,
  reporteListadoAbonos,
  reporteListadoPrecios,
  reporteAnticipos,
  excelListadoAbonos,
  excelLibroVentas
};

export const reducers = {
  [LOADER]: (state, { loader }) => {
    return {
      ...state,
      loader,
    };
  },
  [LISTADO_MONEDAS]: (state, { monedas }) => {
    return {
      ...state,
      monedas,
    };
  },
  [MODULO]: (state, { modulo }) => {
    return {
      ...state,
      modulo,
    };
  },
};

export const initialState = {
  loader: false,
  monedas: [],
  modulo: []
};

export default handleActions(reducers, initialState)